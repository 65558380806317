

import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import stores from "@/store/store";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner }
})
export default class ThankyouForMessage extends Vue {
  public isMailSent = false;
  public async resendEmail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/saveWeHelpData",
        { weHelpId: this.$route.query.id, resendMail: true });
      this.fetchHelpData();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async fetchHelpData() {
    try {
      let id = this.$route.query.id;
      let response = await Axios.get(BASE_API_URL + "broker/fetchHelpData", {
        params: { id: id }
      });
      this.isMailSent = response.data.emailSent;
    } catch (error) {
      console.log(error);
    }
  }
  mounted() {
    this.fetchHelpData();
  }
}
